import styled from 'styled-components';

export const DropdownChoiceWrapper = styled.div`
  max-width: 30%;
  background-color: #98a2bb;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  height: 1.25rem;
  max-height: 1.25rem;
  font-size: 0.7rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.25rem;
  margin-right: 0.25rem;

  &:hover {
    border-color: color: #000000;
  }
`;

export const DropdownChoiceText = styled.div`
  margin-right: 0.25rem;
`;

export const DropdownChoiceButton = styled.div``;

export const DropDownChoiceIcon = styled.i``;
