import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { SecretHeader } from './subComponents/SecretHeader';
import { FullText } from './subComponents/FullText';
import { UrlView } from './subComponents/UrlView';
import { SplitText } from '../../common/forms/SplitText';
import { OptionsButtons } from './subComponents/OptionsButtons';
import { DecryptSecret } from '../../utils/secretFunctions';
import { ViewSecretContainer, SecretWrapper, SecretsWrap } from './styles/ViewSecretStyle';
import { ViewSecretData } from '../../api/secrets/SecretApiFunctions';
import TableLoading from '../table/subComponents/TableLoading';
import { useNotification } from '../../common/notification/Notification';
import { DashboardContext } from '../../context/DashboardContext';
import NoteArea from '../../common/forms/NoteArea';
export const ViewSecret = ({ secret, setViewSecret, admin, isAdmin = false, secretData, setSecretData }) => {
  const { name, id } = secret;
  const dashboard = useContext(DashboardContext);
  const setEditSecret = dashboard?.editSecret[1];
  const { setNotificationText } = useNotification();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const notFound = 'Secret key for this secret was not found, please contact an administrator to reshare.';

  const formatError = obj => (obj && obj['error'] === 'Not found' ? notFound : obj['error']);

  useEffect(() => {
    const getSecretData = () => {
      ViewSecretData(id).then(async secret => {
        if (secret['name']) {
          setSecretData({ ...(await DecryptSecret(secret)), name: secret?.name });
          setLoading(false);
        } else {
          setViewSecret(false);
          setNotificationText({
            type: 'error',
            text: formatError(secret),
          });
        }
      });
    };
    if (loading) getSecretData();
  }, [id, admin, loading, setLoading, secret, setNotificationText, setViewSecret]);

  useEffect(() => {
    Mousetrap.bind('esc', handleClose);
    return () => {
      Mousetrap.unbind('esc');
    };
  });

  const renderUserPwd = () => {
    const username = secretData['Username'];
    const password = secretData['Password'];

    return username && password ? (
      <SplitText label1="Username" text1={username} label2="Password" text2={password} />
    ) : null;
  };

  const renderInfo = () => {
    const infoArray = Object.entries(secretData);
    return infoArray
      .filter(info => info[0] !== 'Username' && info[0] !== 'Password' && info[0] !== '' && info[0] !== 'name')
      .map(info => {
        if (info[0] === 'url' || info[0] === 'Url') {
          const splitUrl = info[1].split('$');
          const protocol = splitUrl[0];
          let address = splitUrl.slice(1).join('$');
          return <UrlView key={info[0]} label="Protocol / Address" text={`${protocol}${address}`} />;
        } else {
          return info[0] === 'note' ? renderNoteArea(info) : <FullText key={info[0]} label={info[0]} text={info[1]} />;
        }
      });
  };

  const renderNoteArea = info => {
    // P4SS-1625: fix note field rendering when empty
    return info[1].length > 0 ? (
      <NoteArea key={info[0]} placeholder="Note" label="Note" value={info[1]} readOnly={true} />
    ) : null;
  };

  const handleClose = () => {
    if (setViewSecret) return setViewSecret(null);
    history.goBack();
  };

  const redirectToEdit = () => {
    if (!setEditSecret && isAdmin) {
      history.push({
        pathname: '/admin/chests/secret/edit',
        row: secret,
        prevPathname: '/',
      });
    } else {
      setEditSecret(secret);
    }
  };

  const checkAccess = () => {
    let hasAccess = false;
    if (isAdmin) {
      hasAccess = true;
    } else if (secret?.chest?.assignments) {
      secret.chest.assignments.map(assignment => {
        // map over array of assignments when secret has more then one
        if (assignment.edit_access) {
          hasAccess = true;
        }
      });
    }
    return hasAccess;
  };
  const renderContent = () => {
    const adminParams = () => checkAccess() && { onClickSecondary: redirectToEdit, secondaryLabel: 'Edit secret' };
    return loading ? (
      <TableLoading />
    ) : (
      <Fragment>
        <SecretWrapper>
          {secretData && renderUserPwd()}
          {secretData && renderInfo()}
          <OptionsButtons onClickPrimary={handleClose} primaryLabel="Close" {...adminParams()} />
        </SecretWrapper>
      </Fragment>
    );
  };

  return (
    <SecretsWrap>
      <SecretHeader name={name ? name : secret.secret} />
      <ViewSecretContainer>{renderContent()}</ViewSecretContainer>
    </SecretsWrap>
  );
};
