import React, { useState, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { CheckPassword } from '../../../utils/AccountFunctions';
import { ErrorMessage, ErrorMessageWrap } from '../../../common/forms/styles/TextInputStyles';
import {
  ProfileModalInfoArea,
  ProfileModalInputArea,
  ProfileModalTextArea,
  ProfileModalTitle,
  ProfileModalText,
  ProfileModalButtonArea,
  ProfileModalButton,
  ProfileBackLink,
  ProfileLoadingModalArea,
} from './styles/ProfileModalStyles';
import { TextInput } from '../../../common/forms/TextInput';
import { blur } from '../../../utils/commonFunctions';
import ChangePasswordSuccess from './ChangePasswordSuccess';
import GenericFormLoading from '../../../common/genericLoading/GenericFormLoading';
import { getValidation } from '../../../utils/validationSuite/validationIndex';

const ChangePassword = ({ title, email, handleConfirm, handleCancel, welcome }) => {
  const [passState, setPassState] = useState('PASSWORD');
  const [currentPass, setCurrentPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [currentError, setCurrentError] = useState('');
  const [newError, setNewError] = useState('');
  const [matchError, setMatchError] = useState('');
  const [currentVisible, toggleCurrentVisibility] = useState(false);
  const [newVisible, toggleNewVisibility] = useState(false);
  const [confirmVisible, toggleConfirmVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  // Keyboard shortcuts
  const changePasswordMousetrap = new Mousetrap();
  useEffect(() => {
    changePasswordMousetrap.bind('esc', () => handleEscapeKey(document.activeElement.id), 'keyup');
    changePasswordMousetrap.bind('enter', submitNewPassword);

    return () => {
      changePasswordMousetrap.unbind('esc', 'keyup');
      changePasswordMousetrap.unbind('enter');
    };
  });

  const handleEscapeKey = id => {
    return id === 'current-password-input' || id === 'new-password-input' || id === 'confirm-password-input'
      ? blur(id)
      : handleCancel();
  };

  const submitNewPassword = async () => {
    const validationId = 'checkPassword';
    const params = {
      password: newPass,
      confirmedPassword: confirmPass,
    };
    let result;
    setLoading(true);
    setCurrentError('');
    setNewError('');
    setMatchError('');
    if (getValidation(validationId)(params)) {
      try {
        result = await CheckPassword(email, currentPass);
        const apiRes = handleConfirm && (await handleConfirm({ oldPass: currentPass, newPass: confirmPass }));

        if (handleConfirm && !apiRes) setPassState('SUCCESS');
        result && setLoading(false);
      } catch (e) {
        setLoading(false);
        // feature/P4SS-1555 - TODO: change error message in backend instead of hardcoding this error
        setCurrentError('Current password is incorrect');
      }
    } else {
      setLoading(false);
      !result && setCurrentError('Incorrect Password');
      newPass.length < 12 && setNewError('New Password must be at least 12 characters');
      newPass !== confirmPass && setMatchError('Passwords must match');
    }
  };
  const renderLoading = () => {
    return (
      <ProfileLoadingModalArea>
        <GenericFormLoading />
      </ProfileLoadingModalArea>
    );
  };
  const getCharacterCount = value => {
    return value.length;
  };
  const renderComponent = () => {
    return (
      <div>
        <ProfileModalTextArea>
          <ProfileModalTitle>{title}</ProfileModalTitle>
          <ProfileModalText>The password should be at least twelve characters long.</ProfileModalText>
        </ProfileModalTextArea>
        <ProfileModalInputArea welcome={welcome}>
          <TextInput
            className={currentError !== '' ? 'error' : ''}
            placeholder="Current Password"
            label="Current Password"
            type={currentVisible ? 'text' : 'password'}
            value={currentPass}
            handleChange={value => setCurrentPass(value)}
            id="current-password-input"
            rightIcon={currentVisible ? 'eyeclose' : 'eye'}
            leftIcon={() => getCharacterCount(currentPass)}
            rightIconClick={() => toggleCurrentVisibility(!currentVisible)}
            autoFocus
            mousetrap
          />
          <TextInput
            className={newError !== '' || matchError !== '' ? 'error' : ''}
            placeholder="New Password"
            label="New Password"
            type={newVisible ? 'text' : 'password'}
            value={newPass}
            handleChange={value => setNewPass(value)}
            id="new-password-input"
            rightIcon={newVisible ? 'eyeclose' : 'eye'}
            leftIcon={() => getCharacterCount(newPass)}
            rightIconClick={() => toggleNewVisibility(!newVisible)}
            mousetrap
          />
          <TextInput
            className={matchError !== '' ? 'error' : ''}
            placeholder="Confirm Password"
            label="Confirm Password"
            type={confirmVisible ? 'text' : 'password'}
            value={confirmPass}
            handleChange={value => setConfirmPass(value)}
            id="confirm-password-input"
            rightIcon={confirmVisible ? 'eyeclose' : 'eye'}
            leftIcon={() => getCharacterCount(confirmPass)}
            rightIconClick={() => toggleConfirmVisibility(!confirmVisible)}
            mousetrap
          />
          <ErrorMessageWrap>
            {currentError !== '' && <ErrorMessage>{currentError}</ErrorMessage>}
            {newError !== '' && <ErrorMessage>{newError}</ErrorMessage>}
            {matchError !== '' && <ErrorMessage>{matchError}</ErrorMessage>}
          </ErrorMessageWrap>
        </ProfileModalInputArea>
        <ProfileModalButtonArea>
          <ProfileModalButton
            className={currentPass === '' || newPass === '' || confirmPass === '' ? 'disable' : ''}
            onClick={submitNewPassword}
          >
            Save
          </ProfileModalButton>
          <ProfileBackLink onClick={handleCancel}>Back</ProfileBackLink>
        </ProfileModalButtonArea>
      </div>
    );
  };

  if (passState === 'SUCCESS') return <ChangePasswordSuccess />;

  return <ProfileModalInfoArea>{loading ? renderLoading() : renderComponent()}</ProfileModalInfoArea>;
};

export default ChangePassword;
