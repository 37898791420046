import styled from 'styled-components';
import { checkHeaders } from '../../../utils/commonFunctions';

export const TableWrap = styled.div`
  width: 50%;
  height: 55vh;
  background-color: ${props => props.theme.tableBgColor};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: -1px auto 0 auto;
  line-height: 3rem;
  overflow-y: auto;

  border-top: 2px solid ${props => props.theme.searchBorderColor};

  &.view {
    height: 100%;
    max-height: 55vh;
  }

  @media screen and (max-width: 767px) {
    width: 95%;
    height: auto;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-y: visible;

  @media screen and (max-width: 767px) {
    max-height: 50vh;
    overflow-y: scroll;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    max-height: 22vh;
  }
`;

export const TableTr = styled.div`
  background-color: inherit;
  display: flex;
  min-height: 50px;
  align-items: center;
  cursor: ${props => (checkHeaders(props?.headers) ? 'default' : 'pointer')};

  @media (-webkit-device-pixel-ratio: 1.5) {
    min-height: 35px;
  }

  &:nth-of-type(even) {
    background-color: ${props => props.theme.altTableRow};
  }

  > div:last-of-type {
    padding-right: 1rem;
    padding-left: 0;
    outline: none;
    margin-left: auto;
  }

  &:hover,
  &.focused {
    background-color: ${props => (props?.headers?.Activity === 'activity' ? null : props.theme.hoverRowBgColor)};
    color: ${props => props.theme.hoverRowColor};

    .table-button {
      border-color: ${props => props.theme.hoverRowColor};
      color: ${props => props.theme.hoverRowColor};
    }
    span {
      border-color: ${props => props.theme.hoverRowColor};
    }
    p,
    i {
      color: ${props => props.theme.hoverRowColor};
    }
  }

  &:focus {
    outline: none;
    background-color: ${props => (props?.headers?.Activity === 'activity' ? null : props.theme.hoverRowBgColor)};
    color: ${props => props.theme.hoverRowColor};

    div div div span {
      border-color: ${props => props.theme.hoverRowColor};
    }
  }

  &.focused {
    background-color: ${props => (props?.headers?.Activity === 'activity' ? null : props.theme.hoverRowBgColor)};
    color: ${props => props.theme.hoverRowColor};

    div div div span {
      border-color: ${props => props.theme.hoverRowColor};
    }
  }

  &:focus .icon-area {
    visibility: visible;
  }

  &.focused .icon-area {
    visibility: visible;
  }
  &:focus .locked-icon-area {
    visibility: visible;
  }
  &.focused .locked-icon-area {
    visibility: visible;
  }
  &:hover .locked-icon-area {
    visibility: visible;
  }
  &:hover .icon-area {
    visibility: ${props => {
      if (props?.headers?.Actions || props?.headers?.AdminActions || props?.headers?.AccountsActions) {
        return 'visible';
      } else {
        return 'hidden';
      }
    }};
  }
  .icon-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    i {
      color: ${props => props.theme.fieldBgColor};
    }
  }

  &:hover .billing-history {
    color: ${props => props.theme.textColor};
    border-left: 0.5px solid ${props => props.theme.fieldBgColor};
    i {
      color: ${props => props.theme.hoverRowColor};
    }

    span {
      color: black;
    }
  }

  &.focused .billing-history {
    color: ${props => props.theme.textColor};
    border-left: 0.5px solid ${props => props.theme.fieldBgColor};
    i {
      color: ${props => props.theme.hoverRowColor};
    }

    span {
      color: black;
    }
  }
`;
export const NoResult = styled.div`
  color: ${props => props.theme.textColor};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-style: italic;
  font-family: 'Lato';
  padding-top: 1rem;
`;
