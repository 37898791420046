import styled from 'styled-components';

export const Modal = styled.div``;

export const ModalOverlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  cursor: pointer;
  z-index: 998;
  opacity: 0.8;
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 0 auto;
  padding: ${({ padding }) => padding};
  left: 0;
  right: 0;
  top: 30%;
  min-width: 300px;
  min-height: 250px;
  width: 30vw;
  height: min-content;
  background-color: ${({ theme }) => theme.tableBgColor};
  border-radius: 5px;
  text-align: center;
  color: ${({ theme }) => theme.textColor};
  z-index: 999;
`;

export const SquaredWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 0 auto;
  padding: ${({ padding }) => padding};
  left: 0;
  right: 0;
  top: 30%;
  min-width: 300px;
  min-height: 300px;
  width: 25%;
  height: 35%;
  background-color: ${({ theme }) => theme.tableBgColor};
  border-radius: 5px;
  text-align: center;
  color: ${({ theme }) => theme.textColor};
  z-index: 999;
`;

export const LargeSquaredWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 0 auto;
  padding: ${({ padding }) => padding};
  left: 0;
  right: 0;
  top: 23%;
  min-width: 500px;
  min-height: 300px;
  width: 45%;
  height: 55%;
  background-color: ${({ theme }) => theme.tableBgColor};
  border-radius: 5px;
  text-align: center;
  color: ${({ theme }) => theme.textColor};
  z-index: 999;
`;

export const Title = styled.div`
  flex: 4;
  font-size: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
