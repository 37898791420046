import React, { useEffect, useState } from 'react';
import { IconWrap, ButtonWrap, Confirm, Cancel } from './styles/ConfirmationModalStyles';
import { Icon } from '../../Icon';
import { placeholderFunc } from '../../../utils/commonFunctions';
import ModalWrap from './ModalWrap';
import Escape from './subComponents/Escape';
import { Input } from './styles/InputModalStyles';
import { Title } from './styles/GenericStyles';
import { useNotification } from '../../../common/notification/Notification';
import { Error } from './styles/SecureConfirmationModalStyles';

const InputModal = ({
  icon = 'alert',
  title,
  placeholder,
  type = 'text',
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  confirmAction = placeholderFunc,
  cancelAction = placeholderFunc,
  escape,
}) => {
  const [input, setInput] = useState('');
  const { notificationText, setNotificationText } = useNotification();

  const handleChange = e => {
    const text = e.target.value;
    setInput(text);
  };

  const handleSubmit = () => confirmAction(input);

  useEffect(() => {
    setNotificationText(), [input];
  });

  return (
    <ModalWrap cancelAction={cancelAction}>
      {escape && <Escape cancelAction={cancelAction} />}
      <IconWrap>
        <Icon name={icon} width={'36px'} />
      </IconWrap>
      <Title>{title}</Title>
      <Input type={type} placeholder={placeholder} onChange={handleChange} />
      {notificationText && notificationText['text'] && (
        <Error role={'error'} visible>
          {notificationText['text']}
        </Error>
      )}
      <ButtonWrap>
        <Confirm onClick={handleSubmit} tabIndex="0" id="confirm-button">
          {confirmText}
        </Confirm>
        <Cancel onClick={cancelAction} tabIndex="0" id="cancel-button">
          {cancelText}
        </Cancel>
      </ButtonWrap>
    </ModalWrap>
  );
};

export default InputModal;
