import styled from 'styled-components';

export const SideMenuWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 4;
  background-color: ${props => props.theme.settingsBgColor};
  color: ${props => props.theme.settingsTextColor};
  border-radius: 0 0 5px 0;
  padding: 2.5rem;

  hr {
    width: 100%;
    margin-top: 1.25rem;
    margin-bottom: 0;
    opacity: 0.3;
  }
`;

export const UserSettingsLoadingWrap = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  height: 1rem;
`;

export const UserSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SettingsWrap = styled.div`
  display: flex;
  flex: 4;
  flex-direction: column;
  padding: 2.5rem;
  color: ${props => props.theme.settingsTextColor};
  background-color: ${props => props.theme.settingsBgColor};
`;

export const SettingsSection = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
`;

export const SettingsHeader = styled.div`
  margin: 0 0 0.5rem;
`;

export const IconSetting = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0 0 0;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const IconWrap = styled.div`
  min-width: 15px;
  margin: 0 0.5rem 0 0;

  path {
    fill: ${({ theme }) => theme.settingsTextColor};
  }
`;

export const DividerLine = styled.hr`
  display: flex;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
`;

export const SettingsSubText = styled.p`
  font-size: 0.7rem;
  margin: -0.625rem 0 0;
`;
