import React, { useContext, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { OptionsModalContext } from '../../../../../context/OptionsModalContext';
import { MenuOptions, DropDownOption } from './styles/BurgerMenuDropdownStyles';

export const BurgerMenuDropdown = ({ menuOptions, highlightedOption, setHighlightedOption }) => {
  const [optionsVisible, setOptions] = useContext(OptionsModalContext);

  // useEffect for keyboard shortcuts
  useEffect(() => {
    if (!highlightedOption) {
      const menuEl = document.getElementById('burger-menu-actions');
      if (menuEl.scrollIntoView) menuEl.scrollIntoView({ behaviour: 'smooth', block: 'nearest' });
    } else {
      const tableEl = document.getElementById('Table');
      if (tableEl) tableEl.classList.add('modal-open');
      Mousetrap.bind(
        'enter',
        () => {
          highlightedOption.handleClick();
          setOptions('');
          setHighlightedOption();
        },
        'keyup',
      );
    }
    Mousetrap.bind('down', () => (optionsVisible.row ? handleDownKey() : null));
    Mousetrap.bind('up', () => optionsVisible.row && handleUpKey());
    return () => {
      optionsVisible && setOptions({});
      const tableEl = document.getElementById('Table');
      if (tableEl) tableEl.classList.remove('modal-open');
      Mousetrap.unbind('down');
      Mousetrap.unbind('up');
      Mousetrap.unbind('enter', 'keyup');
    };
  });

  const handleUpKey = () => {
    if (highlightedOption) {
      const activeIndex = menuOptions.findIndex(option => option.text === highlightedOption.text);
      let newIndex = activeIndex - 1;
      if (activeIndex > 0) {
        setHighlightedOption(menuOptions[newIndex]);
        document.getElementById(menuOptions[newIndex].styleId).classList.add('highlighted');
        document.getElementById(menuOptions[activeIndex].styleId).classList.remove('highlighted');
      }
      if (activeIndex === 0) {
        setOptions({});
        setHighlightedOption('');
        const elements = Array.from(document.getElementsByClassName('table-row'));
        if (elements.length <= 0) return;
        const elIndex = elements.findIndex(el => el === document.activeElement);
        if (elements.length > 0) {
          if (elIndex === elements.length - 1) {
            elements[elements.length - 1].blur();
            elements[elIndex - 1].focus();
            elements[elements.length - 1].focus();
          } else {
            elements[elIndex].blur();
            elements[elIndex + 1].focus();
            elements[elIndex].focus();
          }
        }
      }
    }
  };

  const handleDownKey = () => {
    if (highlightedOption) {
      const activeIndex = menuOptions.findIndex(option => option.text === highlightedOption.text);
      let newIndex = activeIndex + 1;
      if (activeIndex < menuOptions.length - 1) {
        setHighlightedOption(menuOptions[newIndex]);
        document.getElementById(menuOptions[newIndex].styleId).classList.add('highlighted');
        document.getElementById(menuOptions[activeIndex].styleId).classList.remove('highlighted');
      }
    } else {
      setHighlightedOption(menuOptions[0]);
      document.getElementById(menuOptions[0].styleId).classList.add('highlighted');
    }
  };

  const redText = option =>
    option &&
    (option['text'] === 'Delete Permanently' ||
      option['text'] === 'Remove' ||
      option['text'] === 'Remove Chest' ||
      option['text'] === 'Remove Team');

  const returnOptions = options => {
    return options.map(option => (
      <DropDownOption
        role={option.styleId}
        id={option.styleId}
        className={`dd-option`}
        key={option.text}
        onClick={() => option.handleClick()}
        tabIndex="0"
        important={redText(option)}
      >
        {option.text}
      </DropDownOption>
    ));
  };

  return (
    <MenuOptions className={optionsVisible.above && 'above'} id={'burger-menu-actions'} role={'burger-menu-actions'}>
      {returnOptions(menuOptions)}
    </MenuOptions>
  );
};
