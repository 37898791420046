import styled from 'styled-components';

export const IconArea = styled.div`
  visibility: hidden;
  font-size: 16px;
  background-color: ${props => props.theme.textColor};
  color: ${props => props.theme.fieldBgColor};
  text-align: center;
  height: 50px;
  align-items: center;
  justify-content: center !important;
  margin-right: -1rem;
`;
