import React, { Fragment, useState, useEffect, useContext } from 'react';
import Mousetrap from 'mousetrap';
import FooterBar, { DownloadLink, Help, HelpText, LeftContainer } from './styles/FooterStyle';
import ThemeCheckBox from '../../common/checkboxes/styles/ThemeStyle';
import CheckboxSwitch from '../../common/checkboxes/CheckboxSwitch';
import { TextButton } from '../../common/buttons/styles/TextButton';
import { ShortcutsModal } from '../../common/modals/ShortcutsModal';
import { Divider } from '../../common/divider/Divider';
import { OptionsContainer } from './styles/OptionsContainer';
import { Icon } from '../../common/Icon';
import ProfileApi from '../../api/profiles/ProfileApi';
import { isAnInput } from '../../utils/commonFunctions';
import { UserContext } from '../../context/UserProvider';

const Footer = ({ visible }) => {
  const { user, switchTheme } = useContext(UserContext);
  const [modalVisible, toggleShortcutsModal] = useState(false);

  const shiftH = () => {
    return isAnInput() ? null : (toggleShortcutsModal(true), setTimeout(() => toggleShortcutsModal(false), 10000));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) Mousetrap.bind('shift+h', shiftH);
    return () => {
      isMounted = false;
      Mousetrap.unbind('shift+h');
    };
  });

  const handleThemeChange = () => {
    switchTheme();
    ProfileApi.update({ user: { dark_mode: !user.dark_mode } });
  };

  const extensionLink = () => {
    if (navigator.userAgent.indexOf('Chrome') != -1)
      return 'https://chrome.google.com/webstore/detail/recrypt/dcapfoeddoieiemgmoannfooohghbgan?hl=en&authuser=1';
    if (navigator.userAgent.indexOf('Firefox') != -1) return 'https://addons.mozilla.org/en-GB/firefox/addon/recrypt/';
    return '';
  };

  if (!visible) return null;
  return (
    <Fragment>
      <FooterBar>
        <LeftContainer>
          <Help role={'help-link'} href={'https://recrypt.tawk.help/'} target="_blank" rel="noreferrer noopener">
            <Icon name={'help'} width={'20px'} />
            <HelpText>Help</HelpText>
          </Help>
          <Divider />
          {extensionLink && (
            <Fragment>
              <DownloadLink role={'download-link'} href={extensionLink()} target="_blank" rel="noreferrer noopener">
                Download Extension
              </DownloadLink>
              <Divider />
            </Fragment>
          )}
          <p>© Copyright {new Date().getFullYear()} Recrypt</p>
        </LeftContainer>
        <OptionsContainer>
          {modalVisible && <ShortcutsModal />}
          <TextButton
            role={'footer-shortcut-button'}
            onMouseOver={() => toggleShortcutsModal(true)}
            onMouseLeave={() => toggleShortcutsModal(false)}
          >
            Keyboard Shortcuts | Shift + <Icon name="key-h" width="20px" />
          </TextButton>
          <Divider margin={'-4px 2rem 0 2rem'} />
          <ThemeCheckBox>
            <CheckboxSwitch id="toggleSwitch" defaultChecked={!user.dark_mode} onChange={handleThemeChange} />
          </ThemeCheckBox>
        </OptionsContainer>
      </FooterBar>
    </Fragment>
  );
};

export default Footer;
