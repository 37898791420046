import React, { useState, useContext, useEffect, Fragment } from 'react';
import { GetChestUsers } from '../../../../../api/chests/ChestApiFunctions';
import { CellArea } from './styles/UserPermissionsCellStyles';
import { AdminUserContext } from '../../../../../context/AdminContext';

export const UserPermissionsCell = ({ row }) => {
  const [userPermissions, setUserPermissions] = useState('Checking...');
  const userContext = useContext(AdminUserContext);

  useEffect(() => {
    GetChestUsers(row.id).then(users => {
      const viewedUser = users.filter(user => user.id === userContext.id)[0];
      if (viewedUser.administrator) {
        setUserPermissions('Administrator');
      } else if (viewedUser.manager) {
        setUserPermissions('Read & Write');
      } else {
        setUserPermissions('Read only');
      }
    });
  });

  return (
    <Fragment>
      <CellArea role={'Cell'} className="icon-area" tabIndex="0">
        <i className="fas fa-bars"></i>
        <p>{userPermissions}</p>
      </CellArea>
    </Fragment>
  );
};
