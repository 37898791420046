import React, { useState, useEffect } from 'react';
import { RememberMeWrap } from '../styles/RememberMeStyles';

export default function RememberMe() {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (checked) {
      sessionStorage.setItem('rememberMe', checked)
    } else {
      sessionStorage.removeItem('rememberMe')
    }
  }, [checked]);

  return (
    <RememberMeWrap>
      <input type="checkbox" id="checkbox" role="checkbox" checked={checked} onChange={handleChange} />
      <label htmlFor="checkbox">Remember Me</label>
    </RememberMeWrap>
  );
}
