import React from 'react';
import {
  dropdownChoiceWrapper,
  dropdownChoiceText,
  dropdownChoiceButton,
  dropDownChoiceIcon,
} from './styles/DropdownChoiceTagStyles';

export const DropdownChoiceTag = ({ choice, remove }) => (
  <dropdownChoiceWrapper role={'drop-down-choice-tag'} className="tag-wrapper">
    <dropdownChoiceText className="tag-text">{choice.label}</dropdownChoiceText>
    <dropdownChoiceButton role={'drop-down-choice-tag-button'} onClick={() => remove(choice.id)}>
      <dropDownChoiceIcon className="fas fa-times"></dropDownChoiceIcon>
    </dropdownChoiceButton>
  </dropdownChoiceWrapper>
);
