import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { FormContainer } from './styles/FormContainer';
import { FormHeader } from './styles/FormHeader';
import { FormBody, FormDropDowns, FormCustomFields } from './styles/FormBodyStyle';
import { FormFooter, ButtonContainer, FormButton, FormShortcut } from './styles/FormFooterStyle';
import { NewDropdown } from '../../common/forms/NewDropdown';
import LoginCredentials from './subComponents/LoginCredentials';
import PhoneNumber from './subComponents/PhoneNumber';
import Address from './subComponents/Address';
import Note from './subComponents/Note';
import { Icon } from '../../common/Icon';
import { Divider } from '../../common/divider/Divider';
import { GetFormChests, GetChestUsers } from '../../api/chests/ChestApiFunctions';
import { Secret } from '../../steganography/secret';
import BaseApi from '../../api/BaseApi';
import { SaveFooter } from '../../components/admin/adminFooter/footers/SaveFooter';
import { getValidation } from '../../utils/validationSuite/validationIndex';
import { useNotification } from '../../common/notification/Notification';
import LogoutRedirect from '../../common/nav/LogoutRedirect';
import { TextInput } from '../../common/forms/TextInput';
import { blur, capitalize, focus, checkActiveElement } from '../../utils/commonFunctions';
import { errorCheck, removeError } from '../../utils/formFunctions';
import { UserContext } from '../../context/UserProvider';
import { AppLoadingContext } from '../../context/index';
import GenericFormLoading from '../../common/genericLoading/GenericFormLoading';

const SecretForm = ({ setSecret, admin, edit, secret, first, refresh, setViewSecret }) => {
  // Responsible for the determining whether in admin or dashboard for the chests info.
  let secretChestInfo;
  if (secret !== undefined) {
    if (secret['adminChest'] !== undefined) {
      secretChestInfo = secret['adminChest'];
    } else {
      secretChestInfo = secret['chest'];
    }
  }
  const [data, setData] = useState({
    secret: {
      vault_id: secret ? secretChestInfo['id'] : '',
      name: secret ? secret['name'] : '',
      category: secret ? secret.type : 'password',
      encrypted_data: null,
      encryption_iv: null,
      secret_keys_attributes: [null],
    },
  });
  const [subData, setSubData] = useState({});
  const [chestOptions, setChestOptions] = useState([]);
  const [passwordStrength, setStrength] = useState();
  const [typeOptions, setTypeOptions] = useState([]);
  const [chestDropdownVisibility, setChestDropdownVisibility] = useState(false);
  const [typeDropdownVisibility, setTypeDropdownVisibility] = useState(false);
  const [chestOptionSelected, setChestOptionSelected] = useState({
    id: '',
    value: '',
    label: '',
  });
  const [typeOptionSelected, setTypeOptionSelected] = useState({
    id: 1,
    value: 'password',
    label: 'Login Credentials (default)',
  });
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { notificationText, setNotificationText } = useNotification();
  const { loading, setLoading } = useContext(AppLoadingContext);

  useEffect(() => {
    const typeArray = [
      { id: 1, value: 'password', label: 'Login Credentials (default)' },
      { id: 2, value: 'telephone', label: 'Phone Number' },
      { id: 3, value: 'address', label: 'Address' },
      { id: 4, value: 'note', label: 'Note' },
    ];
    GetFormChests({ everything: user['administrator'] }, user['administrator']).then(result => {
      const chestsArray = result.map(chest => ({
        ...chest,
        label: chest['value'],
      }));
      chestsArray[chestsArray.length] = { id: 'blank', label: '', value: '' };

      setChestOptions(chestsArray);
      setTypeOptions(typeArray);
      if (secret) {
        const secretChest = chestsArray.filter(chest => chest.id === secretChestInfo.id)[0];
        const secretCategory = typeArray.filter(type => type.value === secret.type)[0];
        setTypeOptionSelected(secretCategory);
        setChestOptionSelected(secretChest);
      }
    });
  }, [admin, secret, secretChestInfo]);

  const secretCall = async newSecret => {
    if (edit) {
      setViewSecret && setViewSecret({ ...secret, name: newSecret.secret.name });
      await BaseApi.update('secrets', secret.id, newSecret);
      if (admin) {
        if (refresh) refresh();
        history.push({
          pathname: '/admin/chests/secret',
          prevPathname: history.location.pathname,
          refetch: true,
        });
      }
    } else {
      await BaseApi.create('secrets', newSecret).then(res => {
        if (typeof res === 'string') setNotificationText({ type: 'error', text: [res] });
        if (typeof res === 'object') {
          if (!res['error'])
            setNotificationText({
              text: [`${capitalize(res['name'])} created!`],
            });
          if (res['error']) {
            setNotificationText({
              type: 'error',
              text: res['error'],
            });
            return res['error'];
          }
        }
        if (admin && typeof res !== 'string') {
          if (refresh) refresh();
          history.push({
            pathname: '/admin/chests/secret',
            prevPathname: history.location.pathname,
            refetch: true,
          });
        }
        return res;
      });
    }
  };

  useEffect(() => {
    Mousetrap.bind('i', () => !checkActiveElement('chest-dropdown') && focus('secret-name-input'), 'keyup');
    Mousetrap.bind('esc', () => escShortcut(document.activeElement.id));
    Mousetrap.bind('shift+s', shiftS);
    Mousetrap.bind('enter', enterSC);
    return () => {
      Mousetrap.unbind('i', 'keyup');
      Mousetrap.unbind('esc');
      Mousetrap.unbind('shift+s');
      Mousetrap.unbind('enter');
    };
  });

  if (!chestOptions) return <LogoutRedirect />;
  const customFields = () => {
    const type = data.secret.category;
    if (type === 'password' || type === 'Password')
      return (
        <LoginCredentials
          secret={secret || {}}
          data={subData}
          setData={setSubData}
          passwordStrength={passwordStrength}
          setStrength={setStrength}
          notificationText={notificationText}
          setNotificationText={setNotificationText}
          edit={edit}
        />
      );
    if (type === 'telephone')
      return (
        <PhoneNumber
          secret={secret || {}}
          data={subData}
          setData={setSubData}
          notificationText={notificationText}
          setNotificationText={setNotificationText}
        />
      );
    if (type === 'address')
      return (
        <Address
          secret={secret || {}}
          data={subData}
          setData={setSubData}
          notificationText={notificationText}
          setNotificationText={setNotificationText}
        />
      );
    if (type === 'note')
      return <Note tabIndex="0" secret={secret || {}} data={subData} setData={setSubData} edit={edit} />;
    return `${data.secret.name}`;
  };

  const handleChange = (value, key) => {
    if (notificationText) setNotificationText(removeError(key, notificationText));
    setData(prevData => {
      return {
        ...prevData,
        secret: {
          ...prevData.secret,
          [key]: value,
        },
      };
    });
  };

  const handleDDChange = (key, option) => {
    let value;
    if (notificationText) setNotificationText(removeError('dropdown', notificationText));
    if (key === 'vault_id') {
      setChestOptionSelected(option);
      value = option.id;
    } else {
      setTypeOptionSelected(option);
      value = option.value;
    }
    setData(prevData => {
      return {
        ...prevData,
        secret: {
          ...prevData.secret,
          [key]: value,
        },
      };
    });
  };

  const createUrl = subData => subData['url'] && { url: subData['url'].replace('$', ''), form_fill: true };

  const handleSubmit = async () => {
    const validationId = `${data['secret']['category']}Secret`;
    const params = {
      data: { ...data['secret'], ...subData },
      setNotificationText,
    };
    const subDataDollarless = subData['url'] && subData['url'].replace('$', '');
    setLoading(true);
    if (getValidation(validationId)(params)) {
      if (first) first();
      GetChestUsers(data.secret.vault_id).then(async users => {
        const encryptedSecret = await Secret.encrypt(JSON.stringify({ ...subData, url: subDataDollarless }), users);
        const url = createUrl(subData) || {};
        const newSecret = {
          secret: {
            ...data['secret'],
            encrypted_data: encryptedSecret.encryptedData,
            encryption_iv: encryptedSecret.iv,
            secret_keys_attributes: encryptedSecret.unlocks,
            strength: passwordStrength,
            ...url,
          },
        };
        const res = await secretCall(newSecret);

        setLoading(false);
        if (!admin && typeof res !== 'string') setSecret();
      });
    } else {
      setLoading(false);
    }
  };

  const exit = () => {
    if (first) first();
    if (admin) history.goBack();
    if (setSecret) setSecret(false);
  };

  const escShortcut = id => {
    return id === 'secret-name-input' ||
      id === 'chest-dropdown' ||
      id === 'type-dropdown' ||
      id === 'protocol-dropdown' ||
      id === 'hide-secret-toggle'
      ? blur(id)
      : exit();
  };

  const shiftS = () => {
    return checkActiveElement('secret-name-input') ? null : handleSubmit();
  };

  const enterSC = () => {
    if (document.activeElement === document.getElementsByClassName('footer-button')[0]) {
      handleSubmit();
    } else if (document.activeElement === document.getElementsByClassName('footer-button')[1]) {
      exit();
    }
  };

  const renderSecretForm = () => (
    <Fragment>
      <FormBody className="form-body" role={'SecretForm'}>
        <TextInput
          placeholder="Secret Name"
          label="Secret Name"
          value={data.secret.name}
          handleChange={value => handleChange(value, 'name')}
          autoFocus
          mousetrap
          id="secret-name-input"
          className={errorCheck('name', notificationText)}
        />
        <FormDropDowns>
          <NewDropdown
            label="Chests"
            handleDropdownChange={option => handleDDChange('vault_id', option)}
            dropdownOptions={chestOptions}
            dropdownVisibility={chestDropdownVisibility}
            setDropdownVisibility={setChestDropdownVisibility}
            optionSelected={chestOptionSelected}
            id="chest-dropdown"
            className={errorCheck('dropdown', notificationText)}
            select={true}
          />
          <NewDropdown
            label="Secret Type"
            handleDropdownChange={option => handleDDChange('category', option)}
            dropdownOptions={typeOptions}
            dropdownVisibility={typeDropdownVisibility}
            setDropdownVisibility={setTypeDropdownVisibility}
            optionSelected={typeOptionSelected}
            id="type-dropdown"
            className="no-right-margin"
          />
        </FormDropDowns>
        <FormCustomFields>{customFields()}</FormCustomFields>
      </FormBody>
      {admin && <SaveFooter handleSubmit={handleSubmit} first={first} edit={edit} />}
    </Fragment>
  );

  const renderDashboardFormFooter = () => (
    <FormFooter>
      <ButtonContainer>
        <FormButton
          role={'FooterButton'}
          tabIndex="0"
          id={1}
          onClick={() => {
            handleSubmit();
          }}
        >
          <i className="fas fa-check-circle" />
          {edit ? 'Confirm Changes' : 'Save'}
        </FormButton>
        <Divider />
        <FormButton role={'FooterButton'} tabIndex="0" onClick={() => setSecret(false)}>
          Cancel
        </FormButton>
      </ButtonContainer>
      <FormShortcut>
        Shift + <Icon name="key-s" width="18px" />
      </FormShortcut>
    </FormFooter>
  );

  const renderForm = () => {
    if (admin) {
      return renderSecretForm();
    } else {
      return (
        <FormContainer>
          <FormHeader>{edit ? 'Edit' : 'New'} Secret</FormHeader>
          {renderSecretForm()}
          {renderDashboardFormFooter()}
        </FormContainer>
      );
    }
  };

  return <Fragment>{loading ? <GenericFormLoading /> : renderForm()}</Fragment>;
};

export default SecretForm;
