import styled from 'styled-components';

export const TitleWrap = styled.div`
  flex: 2;
  padding: 2rem 0 0 0;
  font-size: 32px;
  background-color: ${({ theme }) => theme.bgColor};
`;

export const Title = styled.div`
  font-weight: bold;
`;

export const Subtitle = styled.div``;

export const BoxWrap = styled.div`
  display: flex;
  flex: 5;
  flex-direction: row;
  margin: -2rem 0 0 0;
`;

export const BoxCol = styled.div`
  display: flex;
  flex: 8;
  flex-direction: column;
  align-items: center;
  width: 40%;

  &:first-child {
    padding: 0 0 0 1rem;
  }

  &:last-child {
    padding: 0 1rem 0 0;
  }
`;

export const Box = styled.div`
  display: flex;
  flex: 6;
  flex-direction: column;
  padding: 1rem 0 0 0;
  background-color: ${({ theme }) => theme.fieldBgColor};
  margin: 0 0 1rem 0;
  width: 90%;
  border-radius: 0.2rem;
  border: 1px solid ${({ theme }) => theme.textColor};
`;

export const IconWrap = styled.div`
  flex: 3;
  svg {
    g {
      fill: ${({ theme }) => theme.highlightColor};
    }
  }
`;

export const BoxTitle = styled.div`
  flex: 2;
  font-weight: bold;
`;

export const BoxText = styled.div`
  flex: 7;
  font-size: 0.8rem;
  margin: 0 1rem;
`;

export const BoxPriceWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const BoxPriceCurrency = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
  margin-top: 1rem;
  -webkit-transform: translateX(-9px);
  -ms-transform: translateX(-9px);
  transform: translateX(-9px);
`;
export const BoxPriceNum = styled.div`
  font-size: 4.6rem;
  font-weight: 700;
`;
export const BoxPriceUnit = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 4px;
  margin-left: 5px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-transform: translateY(-12px);
  -ms-transform: translateY(-12px);
  transform: translateY(-12px);
`;
export const BoxPriceText = styled.div`
  font-size: 0.8rem;
`;

export const BoxPlanTitle = styled.div`
  flex: 3;
  font-weight: bold;
`;

export const BoxPlanText = styled.div`
  flex: 3;
  font-size: 0.8rem;
  line-height: 0;
`;

export const SubmissionWrap = styled.div`
  flex: 1.3;
`;
export const SubmissionText = styled.div`
  font-size: 0.8rem;
`;
export const Button = styled.div`
  background-color: ${({ theme }) => theme.highlightColor};
  margin: 0.5rem auto 0 auto;
  width: 30%;
  border-radius: 0.2rem;
  padding: 0.6rem;
  color: ${({ theme }) => theme.hoverRowColor};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
