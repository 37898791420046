import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import zxcvbn from 'zxcvbn';
import { NextButton, InfoInput, Fieldset, InfoText, Heading, Subheading, TextLink } from './styles/ComponentStyles';
import PasswordMeter from '../../common/forms/PasswordMeter';
import { CreateOwner } from '../../api/Registration';
import { useNotification } from '../../common/notification/Notification';
import { ValidationHeader } from './ValidationHeader';
import { AppLoadingContext } from '../../context';
import GenericFormLoading from '../../common/genericLoading/GenericFormLoading';

export const PasswordCreation = ({ setDisplay, setData, data }) => {
  const [password, setPassword] = useState('');
  const [passwordStrength, setStrength] = useState();
  const [passwordVisible, togglePasswordVisibility] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmVisible, toggleConfirmVisibility] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [cursorDisabled, setCursorDisabled] = useState(false);
  const { loading, setLoading } = useContext(AppLoadingContext);
  const { notificationText, setNotificationText } = useNotification();
  const iconStyles = { backgroundColor: 'white', paddingLeft: '0.5rem', paddingTop: '0.25rem' };
  const passwordInputEl = useRef();
  const confirmPasswordInputEl = useRef();

  const handleClick = () => {
    if (enabled) {
      setLoading(true);
      setData({ ...data, password: password });
      setCursorDisabled(true);
      CreateOwner({ ...data, password: password }).then(async response => {
        if (`${response.status}`.slice(0, 2) === '20') {
          setDisplay('emailconfirmation');
        }
        setCursorDisabled(false);
        setLoading(false);
        setNotificationText({
          text:
            `${response.status}` === '422'
              ? ['This email address is already taken.']
              : ['There has been an error while processing your registration. Please try again.'],
          type: 'error',
        });
      });
      if (confirmPassword !== password) {
        setNotificationText({
          text: ['Please ensure passwords match'],
          type: 'error',
        });
      }
      if (password.length < 12) {
        setNotificationText({
          text: ['Please enter a password with a minimum length of 12 characters'],
          type: 'error',
        });
      }
    }
  };

  const handlePassword = password => {
    const strength = zxcvbn(password).score + 1;
    setStrength(strength);
    setPassword(password);
  };

  const handleConfirm = confirmPwd => {
    setConfirmPassword(confirmPwd);
  };

  const focusElement = id => {
    if (id === 'password-input') {
      passwordInputEl.current.focus();
    } else if (id === 'confirm-password-input') {
      confirmPasswordInputEl.current.focus();
    }
  };

  useEffect(() => {
    if (password.length >= 12 && confirmPassword === password) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [confirmPassword, password]);

  return (
    <Fragment>
      <ValidationHeader notificationText={notificationText} setNotificationText={setNotificationText} />
      {loading ? (
        <GenericFormLoading />
      ) : (
        <Fragment>
          <Heading role={'password-creation-heading'}>Choose your Password</Heading>
          <Subheading role={'password-creation-subheading'}>
            This is the only password you have to remember. Make sure you never forget it. If you lose it, you will lose
            all your data.
          </Subheading>
          <Fieldset role={'password-creation-fieldset'}>
            {password !== '' && <legend role={'password-creation-legend'}>Password</legend>}
            <InfoInput
              role={document.activeElement === passwordInputEl.current ? 'password-input-focused' : 'password-input'}
              id={'password-input'}
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Password"
              onChange={e => handlePassword(e.target.value)}
              ref={passwordInputEl}
            ></InfoInput>
            {password && (
              <i
                role={'password-creation-eye'}
                style={iconStyles}
                onClick={() => {
                  focusElement('password-input');
                  togglePasswordVisibility(!passwordVisible);
                }}
                className={passwordVisible ? 'far fa-eye-slash' : 'far fa-eye'}
              ></i>
            )}
          </Fieldset>
          {password !== '' && <PasswordMeter strength={passwordStrength} />}
          <Fieldset role={'password-creation-fieldset'}>
            {confirmPassword !== '' && <legend role={'confirm-password-legend'}>Confirm Password</legend>}
            <InfoInput
              role={
                document.activeElement === confirmPasswordInputEl.current
                  ? 'confirm-password-input-focused'
                  : 'confirm-password-input'
              }
              id={'confirm-password-input'}
              type={confirmVisible ? 'text' : 'password'}
              placeholder="Confirm Password"
              onChange={e => handleConfirm(e.target.value)}
              ref={confirmPasswordInputEl}
            ></InfoInput>
            {confirmPassword && (
              <i
                role={'confirm-password-creation-eye'}
                style={iconStyles}
                onClick={() => {
                  focusElement('confirm-password-input');
                  toggleConfirmVisibility(!confirmVisible);
                }}
                className={confirmVisible ? 'far fa-eye-slash' : 'far fa-eye'}
              ></i>
            )}
          </Fieldset>
          <InfoText role={'password-creation-infoText'}>The password must be at least twelve characters long.</InfoText>
          <NextButton
            role={'password-creation-create-button'}
            className={`${enabled && 'enabled'} ${cursorDisabled && 'cursorDisabled'}`}
            onClick={() => handleClick()}
          >
            Create Recrypt Account
          </NextButton>
          <TextLink role={'password-creation-back-button'} onClick={() => setDisplay('initial')}>
            Back
          </TextLink>
        </Fragment>
      )}
    </Fragment>
  );
};
