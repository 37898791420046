import React, { Fragment } from 'react';
import ChangePassword from '../../../components/profile/subComponents/ChangePassword';
import { ChangeAccountPassword } from '../../../utils/AccountFunctions';
import {
  ProfileModalOverlay,
  ProfileModalContainer,
} from '../../../components/profile/subComponents/styles/ProfileModalStyles';

const ChangePasswordModal = ({ user, setModal }) => {
  return (
    <Fragment>
      <ProfileModalOverlay role={'profile-modal-overlay'} onClick={() => setModal('')} />
      <ProfileModalContainer>
        <ChangePassword
          title="Change Password"
          email={user.email}
          handleConfirm={data => ChangeAccountPassword(data.oldPass, data.newPass, user.email, setModal)}
          handleCancel={() => setModal('')}
        />
      </ProfileModalContainer>
    </Fragment>
  );
};

export default ChangePasswordModal;
