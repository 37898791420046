import ProfileApi from '../api/profiles/ProfileApi';

export const extractFromUrl = key => {
  const params = new URL(document.location).searchParams;
  return params.get(`${key}`);
};

export const scrollEnded = target => Math.round(target.scrollHeight - target.scrollTop) === target.clientHeight;

export const getNodeText = e => {
  if (e.target.childNodes[0]['nodeValue'] === undefined) return e.target.textContent;
  return e.target.childNodes[0].nodeValue;
};

export const generatePassword = (uppercase, lowercase, incNumbers, incSymbols, length) => {
  const characters = _getAllCharacters(uppercase, lowercase, incNumbers, incSymbols);
  let password = '';
  _times(length)(() => {
    const character = Math.floor(Math.random() * characters.length);
    password += characters.substring(character, character + 1);
  });
  return password;
};

export const capitalize = str => {
  if (!str) return str;
  if (!typeof str === 'string') return str;

  return `${str}`[0].toUpperCase() + `${str}`.slice(1);
};

export const placeholderFunc = () => console.log('Component not assigned a function.');

export const FindData = (id, dataset) => dataset.find(row => row.id === id);

export const formatDate = dateStr => {
  const date = new Date(dateStr);
  return date.toUTCString();
};

export const formatAuditLogDate = dateStr => {
  const date = new Date(dateStr);
  const today = new Date();
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  const minutes = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
  const ampm = date.getHours() > 11 ? 'PM' : 'AM';
  if (day === today.getDate() && month === today.getMonth() && year === today.getFullYear()) {
    return `Today at ${hours}:${minutes} ${ampm}`;
  } else {
    return `${day}/${month}/${year} at ${hours}:${minutes} ${ampm}`;
  }
};

export const formatUser = userString => {
  const parsedString = userString.split(/[(/)/]/);
  if (!parsedString || parsedString.length < 2) return userString;
  return parsedString[1];
};

export const secondsToDate = secs => {
  let epoch = new Date(1970, 0, 1);
  epoch.setSeconds(secs);
  return epoch.toDateString();
};

export const numToPounds = num => `£${(num / 100).toFixed(2)}`;

export const _getAllCharacters = (incUpper, incLower, incNumbers, incSymbols) => {
  const uppercase = incUpper ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' : '';
  const lowercase = incLower ? 'abcdefghijklmnopqrstuvwxyz' : '';
  const numbers = incNumbers ? '0123456789' : '';
  const symbols = incSymbols ? '!"#$%&\'()*+,-./:;<=>?@^[\\]^_`{|}~' : '';
  return uppercase + lowercase + numbers + symbols;
};

export const _times = i => func => {
  if (i > 0) {
    func();
    _times(i - 1)(func);
  }
};

export const blur = id => document.getElementById(id) !== null && document.getElementById(id).blur();

export const focus = id => document.getElementById(id) !== null && document.getElementById(id).focus();

// This determines if the active element is an input - used for keyboard shortcuts
export const isAnInput = () =>
  checkActiveElement('searchbar') ||
  checkActiveElement('chest-name-input') ||
  checkActiveElement('secret-name-input') ||
  checkActiveElement('team-name-input') ||
  checkActiveElement('user-name-input') ||
  checkActiveElement('user-email-input') ||
  checkActiveElement('admin-searchbar') ||
  checkActiveElement('current-password-input') ||
  checkActiveElement('new-password-input') ||
  checkActiveElement('confirm-password-input') ||
  checkActiveElement('new-email-input') ||
  checkActiveElement('confirm-email-input');

export const getDate = (monthsAdded = 0) => {
  const d = new Date();
  return new Date(d.setMonth(d.getMonth() + monthsAdded));
};

export const checkActiveElement = id => {
  return document.activeElement === document.getElementById(id);
};

export const checkOnboardingState = async (welcome, update, history) => {
  if (welcome && Object.values(welcome).every(item => item)) {
    await ProfileApi.update({
      user: { first_time: false },
    });
    update('first_time', false);
    history.push('/');
  }
};

// checks table headers to determine the cursor should be a pointer or default
export const checkHeaders = headers => {
  return headers?.Activity === 'activity' || headers?.AccountsActions === 'billingAccountsActions';
};
