import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import Table from '../../table/Table';
import { UserSettings } from './subComponents/UserSettings';
import {
  AdminUsersShowWrap,
  UserShowTableWrap,
  AdminUsersShowTopWrap,
  AdminUsersShowBottomWrap,
  UserLoadingWrap,
  UserLogsText,
} from './styles/AdminUsersShowStyles';
import UserActiveLogHeader from '../adminHeader/headers/UserActiveLogHeader';
import { GetUserChests } from '../../../api/chests/ChestApiFunctions';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';
import { AdminUserContext } from '../../../context/AdminContext';
import { UserLogs } from '../../../api/auditLogs/AuditLogApiFunctions';
import GenericLoading from '../../../common/genericLoading/GenericLoading';
import { scrollEnded } from '../../../utils/commonFunctions';
import { TableBottomLoading } from '../../table/subComponents/styles/TableBottomLoading';
import Loading from '../../../images/loading_02.gif';

const AdminUsersShow = () => {
  const [data, setData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [lastActivity, setLastActivity] = useState({
    date: '',
    activity: '',
    id: '',
  });
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const row = useMemo(() => location.row || (location.state && location.state['row']) || [], [location]);
  const searchData = `${row.name} (${row.email}) (${row.id})`;
  const headers = { Chests: 'name', Permissions: 'userPermissions' }; 
  //the only place I could find setting permissions to userPermissions in the app
  const primaryWidths = ['50%', '50%'];
  const altHeaders = { Date: 'date', Activity: 'activity', ID: 'id' };
  const altWidths = ['30%', '40%', '30%'];
  const jsonLimit = 10;
  const [offset, setOffset] = useState(0);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) setLoading(true);
    GetUserChests(row.id).then(async result => {
      if (isMounted) setData(result);
      const logData = await UserLogs(searchData, jsonLimit, 0);
      if (isMounted) setAuditData(logData);
      if (logData.length > 0) {
        if (isMounted) setLastActivity(logData[0]);
      }
      if (isMounted) setLoading(false);
    });
    return () => (isMounted = false);
  }, [row, searchData]);

  const handleScrollToBottom = async e => {
    const target = e.target;
    if (scrollEnded(target)) {
      const tableTotal = auditData[0] && auditData[0]['total'];
      const newOffset = offset + jsonLimit;
      if (newOffset < tableTotal) {
        setDataLoading(true);
        setOffset(newOffset);
        const logData = await UserLogs(searchData, jsonLimit, newOffset);
        data.length !== 0 ? setAuditData([...auditData, ...logData]) : setOffset(newOffset - jsonLimit);
        setDataLoading(false);
      }
    }
  };

  if (row.length === 0) return <Redirect to={'/admin/teams'} />;
  if (!data) return <LogoutRedirect />;
  return (
    <AdminUsersShowWrap>
      <AdminUsersShowTopWrap>
        <UserShowTableWrap id="Table">
          <AdminUserContext.Provider value={row}>
            <Table columnWidths={primaryWidths} headers={headers} data={data} />
          </AdminUserContext.Provider>
        </UserShowTableWrap>
        <UserSettings
          user={row}
          lastActivity={lastActivity}
          loading={loading}
          locked={row.locked}
          permissions={row.permissions}
        />
      </AdminUsersShowTopWrap>
      <AdminUsersShowBottomWrap>
        <UserActiveLogHeader />
        <UserShowTableWrap role={'user-show-table-wrap'} onScroll={e => handleScrollToBottom(e)}>
          {loading ? (
            <UserLoadingWrap>
              <GenericLoading />
            </UserLoadingWrap>
          ) : auditData.length > 1 ? (
            <Fragment>
              <Table columnWidths={altWidths} headers={altHeaders} data={auditData} />
            </Fragment>
          ) : (
            <UserLogsText>No Recent Logs</UserLogsText>
          )}
        </UserShowTableWrap>
        {dataLoading && (
          <TableBottomLoading role={'table-button-loading'}>
            <img className="small-loading" src={Loading} alt="Loading..." />
          </TableBottomLoading>
        )}
      </AdminUsersShowBottomWrap>
    </AdminUsersShowWrap>
  );
};

export default AdminUsersShow;
