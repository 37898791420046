import styled from 'styled-components';

const UserActiveLogWrap = styled.div`
  background: ${props => props.theme.fieldBgColor};
  padding: 0.25rem 1.25rem;
  font-size: 1.2rem;
  border-radius: 5px 5px 0 0;
  display: inline-flex;
  color: ${props => props.theme.settingsTextColor};
  border-bottom: 1px ${props => props.theme.bgColor} solid;
  line-height: 3.5rem;

  svg {
    margin-right: 0.75rem;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    line-height: 3rem;
    font-size: 1.1rem;
  }
`;

export default UserActiveLogWrap;
