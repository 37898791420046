import React, { useState, useContext, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import CenterNav from '../components/nav/CenterNav';
import Secrets from '../components/secrets/Secrets';
import Chests from '../components/chests/Chests';
import { DashboardContent, DashboardHeader, DashboardLink } from './styles/DashboardStyle';
import { CSSTransition } from 'react-transition-group';
import SecretForm from '../components/forms/SecretForm';
import EditMySecret from '../components/mySecrets/subComponents/EditMySecret';
import TableLoading from '../components/table/subComponents/TableLoading';
import { DashboardContext } from '../context/DashboardContext';
import { isAnInput } from '../utils/commonFunctions';
import { UserContext } from '../context/UserProvider';

const Dashboard = ({ displayDashboard, setDisplayDashboard }) => {
  const { user } = useContext(UserContext);
  const { first_time, must_change_password } = user;
  const [dashboardContext, setDashboard] = useState('Secrets');
  const [addSecret, setAddSecret] = useState(false);
  const [editSecret, setEditSecret] = useState();
  const [searchText, setSearchText] = useState('');
  const location = useLocation();
  const [viewSecret, setViewSecret] = useState();
  const [secretData, setSecretData] = useState();

  const stateObject = {
    display: displayDashboard,
    setDisplay: setDisplayDashboard,
    searchText,
    setSearchText,
    addSecret,
    setAddSecret,
    editSecret,
    setEditSecret,
    viewSecret,
    setViewSecret,
    secretData,
    setSecretData,
  };

  const dashboardInfo = {
    editSecret: [editSecret, setEditSecret],
  };

  const shiftUp = () => {
    setAddSecret(false);
    setDisplayDashboard(false);
  };

  const shiftDown = () => {
    setDisplayDashboard(true);
  };

  const shiftN = () => {
    return isAnInput() ? null : setAddSecret(true);
  };

  const dashboardMousetrap = new Mousetrap();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dashboardMousetrap.bind('shift+n', shiftN, 'keyup');
      dashboardMousetrap.bind('shift+down', shiftDown);
      dashboardMousetrap.bind('shift+up', shiftUp);
    }
    return () => {
      isMounted = false;
      dashboardMousetrap.unbind('shift+n', 'keyup');
      dashboardMousetrap.unbind('shift+down');
      dashboardMousetrap.unbind('shift+up');
    };
  }, [viewSecret, secretData, viewSecret, stateObject, setSecretData, editSecret, setEditSecret, dashboardInfo]);
  if (first_time) return <Redirect to={{ pathname: '/welcome' }} />;
  if (must_change_password) return <Redirect to={{ pathname: '/profile/personal_password' }} />;

  const DashboardSwitch = () => {
    if (addSecret) return <SecretForm setSecret={setAddSecret} edit={false} />;
    if (editSecret) return <EditMySecret stateObject={stateObject} />;
    if (dashboardContext === 'Secrets') return <Secrets stateObject={stateObject} />;
    if (dashboardContext === 'Chests') return <Chests stateObject={stateObject} />;
    return <TableLoading />;
  };
  return (
    <DashboardContext.Provider value={dashboardInfo}>
      <DashboardContent>
        <CSSTransition in={location.pathname === '/'} timeout={500} classNames="dashboard" appear>
          <div>
            <DashboardHeader>
              <CenterNav
                activeLink={dashboardContext}
                setDashboard={setDashboard}
                setEditSecret={setEditSecret}
                setViewSecret={setViewSecret}
                viewSecret={viewSecret}
                setAddSecret={setAddSecret}
              />
              <DashboardLink onClick={() => setAddSecret(true)}>
                <i className="fas fa-plus"></i>
                Add Secret
              </DashboardLink>
            </DashboardHeader>
            {DashboardSwitch()}
          </div>
        </CSSTransition>
      </DashboardContent>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
