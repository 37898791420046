import styled from 'styled-components';

export const AccountsTable = styled.div`
  font-size: 14px;
  background-color: ${props => props.theme.tableBgColor};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: -1px auto 0 auto;
  line-height: 3rem;
  overflow-y: auto;
`;
