import React from 'react';
import { Container, FieldLegend, TextArea } from './styles/NoteAreaStyles';

const NoteArea = ({ placeholder, label, value, onChange, edit, readOnly }) => {
  return (
    <Container>
      <TextArea
        role={'NoteArea'}
        className={'area'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
      />
      <FieldLegend className={'legend'} edit={edit}>
        {label}
      </FieldLegend>
    </Container>
  );
};

export default NoteArea;
